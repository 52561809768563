import React from 'react';
import Layout from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import EmployerList from '../../components/organisms/employer-list/EmployerList';
import AuthWrapper from '../../components/layout/AuthWrapper';

const ListEmployersPage = () => {
  return (
    <AuthWrapper>
      <Head title="Employers" />
      <Layout
        section="employers"
        breadcrumbs={[{ link: '/', label: 'Home' }, { label: 'Employers' }]}
      >
        <EmployerList />
      </Layout>
    </AuthWrapper>
  );
};

export default ListEmployersPage;
