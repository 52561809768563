import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { IEmployerInList } from '../../../context-providers/employer/employer-reducer';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { formatDateTime } from '../../../utils/dates';
import { usePagedRequest } from '../../../utils/paged-request';
import Button from '../../atoms/button/Button';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import Paging from '../../atoms/paging/Paging';
import Stack from '../../atoms/stack/Stack';
import Table from '../../atoms/table/Table';
import { SingleLineCell, TableActions } from '../../atoms/table/Table.style';
import { IconDelete, IconPlus, IconShow } from '../../icons';
import { FullWidthContainer } from '../../layout/Layout.style';
import { fetchAPI } from '../../../utils/fetch-api';
import { userHasPermission } from '../../../context-providers/user/user-permission';
import { Permission } from '../../../context-providers/enums/global-enums';
import { getUserClaims } from '../../../utils/jwt-decode';
import { deleteQueryParams, getAllQueryVar } from '../../../utils/query-vars';
import EmployerFilter from '../../atoms/filter/filter-types/employer-filter';
import ActionLink from '../../atoms/custom-elements/linkaction.style';
import { FlexGrid } from '../../atoms/custom-elements/Flex.style';
import ExportLearnerHelper, {
  ILearnerCentre,
  ILearnerPlacement,
} from '../../../utils/exportLearnerHelper';
import DeleteData from '../../modals/forms/DeleteData';
import CreateEmployer from '../../modals/employer/CreateEmployer';

const isBrowser = typeof window !== 'undefined';

const EmployerList = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const extractQueryVars = (name: string): string[] => {
    const params = getAllQueryVar(name) ?? [];
    if (params.length === 1) {
      return params[0].split(',');
    }
    return params;
  };

  const [centres, setCentres] = useState<ILearnerCentre[]>([]);
  const [centresLoading, setCentresLoading] = useState<boolean>(false);
  const [centresLoaded, setCentresLoaded] = useState<boolean>(false);
  const getCentres = (): void => {
    if (!centresLoaded && !centresLoading) {
      setCentresLoading(true);
      ExportLearnerHelper.getCentres().then((response) => {
        setCentres(response);
        setCentresLoaded(true);
      });
    }
  };
  useEffect(() => {
    getCentres();
  }, [getCentres]);

  const employersParams = extractQueryVars('employers');
  const hasActiveFilters = employersParams.length > 0;

  const user = getUserClaims();
  let canDeleteEmployer: boolean = false;

  if (user) {
    canDeleteEmployer = userHasPermission(
      user.permissions,
      Permission.DeleteEmployer,
    );
  }

  const showModal = useShowModal();
  const employers = usePagedRequest<IEmployerInList>('EmployerRecords', {
    sortColumnName: 'lastmodified',
    employers: employersParams.join(','),
  });

  const onConfirmDelete = async (employer: IEmployerInList) => {
    const res = await fetchAPI<string>({
      path: `EmployerRecords/${employer?.id}`,
      method: 'DELETE',
      responseType: 'text',
    });

    if (res.success) {
      window.location.reload();
    }
  };

  const clearAllFilters = () => {
    const url = deleteQueryParams('employers');
    navigate(url || '');
  };

  const renderRow = (data: IEmployerInList) => [
    <strong key="1" data-qa="employers-tbl-name">
      {data.employerName}
    </strong>,
    <Stack key="2" stackMultiplier={0.5}>
      {data.sectors.map((sector, index) => (
        <SingleLineCell
          key={`${data.employerName}Sector${index}`}
          data-qa={`${data.employerName.replace(
            ' ',
            '-',
          )}-${sector.name.replace(' ', '-')}`}
        >
          {sector.name}
        </SingleLineCell>
      ))}
    </Stack>,
    <Stack key="3" stackMultiplier={0.5}>
      {data.sectors.map((sector, index) => (
        <SingleLineCell
          key={`${data.employerName}Learner${index}`}
          data-qa={`${sector.name.replace(' ', '-')}-learners`}
        >
          {sector.learners.length > 0
            ? `${sector.learners.length} learner(s)`
            : 'Unassigned'}
        </SingleLineCell>
      ))}
    </Stack>,
    formatDateTime(data.lastModified),
    <TableActions
      key="4"
      data-qa="employers-btn-view"
      onClick={() => {
        ReactGA.event({
          category: 'Button',
          action: 'View employer',
        });
      }}
    >
      <Link to={`/employer/?eid=${data.id}`}>
        <IconShow responsive />
        <span className="sr-only">Edit {data.employerName} form</span>
      </Link>

      {!data.sectors.some((sector) => sector.learners.length > 0) && (
        <SingleLineCell key={`${data.employerName} Employer`}>
          <button
            type="button"
            onClick={() =>
              showModal(DeleteData, {
                form: data,
                onConfirmDelete: () => {
                  onConfirmDelete(data);
                },
              })
            }
            disabled={!canDeleteEmployer}
          >
            <IconDelete fill={!canDeleteEmployer ? '#ccc' : ''} responsive />
            <span className="sr-only">Delete {data.employerName} Employer</span>
          </button>
        </SingleLineCell>
      )}
    </TableActions>,
  ];

  return (
    <FullWidthContainer>
      <Stack stackMultiplier={2}>
        <GridFlex gutters={false} align="flex-end" justify="space-between">
          <GridFlexItem gutters={false} small={8}>
            <Stack stackMultiplier={1}>
              <h1 data-qa="employers-header">Employer records</h1>
              <p>
                You can add a new employer record, manage records or assign
                learners to an employer.
              </p>
            </Stack>
          </GridFlexItem>

          <Button
            iconLeft={<IconPlus responsive />}
            dataQa="employers-btn-add"
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'New employer record',
              });
              showModal(CreateEmployer, {
                onCreated: (employerId: string) => {
                  navigate(`/employer/?eid=${employerId}`);
                },
                centres,
              });
            }}
          >
            New employer record
          </Button>
        </GridFlex>

        <FlexGrid column={2} row={1}>
          <EmployerFilter />
          {hasActiveFilters && (
            <ActionLink onClick={clearAllFilters}>Clear all</ActionLink>
          )}
        </FlexGrid>
        <Table
          caption="List of employers"
          data={employers.data}
          headerItems={[
            { label: 'Employer name', sortColumnName: 'employername' },
            { label: 'Sector(s)' },
            { label: 'Assigned learners' },
            { label: 'Last modified', sortColumnName: 'lastmodified' },
            { label: 'Actions' },
          ]}
          renderRow={renderRow}
          paged={employers.pagedParams}
        />
        <Paging pageCount={employers.pageCount} />
      </Stack>
    </FullWidthContainer>
  );
};

export default EmployerList;
