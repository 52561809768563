import { produce } from 'immer';
import set from 'lodash/set';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import {
  createEmptyEmployer,
  createEmptyEmployerSector,
} from '../../../context-providers/employer/employer-hooks';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { IconArrowRight, IconBin, IconError, IconPlus } from '../../icons';
import {
  AddCircle,
  AddSectorButton,
  SectorField,
  DetailsCard,
  RadioWrapper,
} from '../ModalContent.style';
import { MaxLength } from '../../../context-providers/enums/global-enums';
import ExportLearnerHelper, {
  ILearnerCentre,
  ILearnerPlacement,
} from '../../../utils/exportLearnerHelper';
import Radio from '../../atoms/form-fields/radio/Radio';
import Select, { IOption } from '../../atoms/form-fields/select/Select';
import FilterSingleItem from '../../atoms/filter/FilterSingleItem';
import { FilterItem } from '../../atoms/filter/models/filter-item';

export interface ICreateEmployer {
  onCreated?: (employerId: string) => void;
  centres: ILearnerCentre[];
}

const CreateEmployer = ({ onCreated, centres }: ICreateEmployer) => {
  const closeModal = useCloseModal();
  const [employerNameRadio, setEmployerNameRadio] = useState('name');
  const [step, setStep] = useState(0);
  const [submited, setSubmited] = useState(false);
  const [employer, setEmployer] = useState(createEmptyEmployer());
  const [placements, setPlacements] = useState<ILearnerPlacement[]>([]);
  const [placementsLoading, setPlacementsLoading] = useState<boolean>(false);
  const [placementsLoaded, setPlacementsLoaded] = useState<boolean>(false);
  const getPlacements = (): void => {
    if (hasCentreOptions && !placementsLoaded && !placementsLoading) {
      const centreOption = centreOptions.find(
        (c) => c.value === employer?.onefileOrganisationId.toString(),
      );
      if (centreOption !== undefined) {
        setPlacementsLoading(true);
        ExportLearnerHelper.getPlacements(centreOption.value).then(
          (response) => {
            setPlacements(response);
            setPlacementsLoaded(true);
            setPlacementsLoading(false);
          },
        );
      }
    }
  };
  useEffect(() => {
    getPlacements();
  }, [getPlacements]);

  const hasCentreOptions: boolean = centres.length > 0;
  const hasPlacementOptions: boolean = placements.length > 0;
  const centreOptions: IOption[] = centres.map((i) => ({
    value: i.id.toString(),
    label: i.name,
  }));

  const placementOptions: FilterItem[] = placements.map((i) => ({
    id: i.id,
    name: i.name,
  }));

  const currentCentreOption: IOption | undefined = centreOptions.find(
    (c) => c.value === employer.onefileOrganisationId.toString(),
  );

  const handleCentreChange = (value: string) => {
    let selectedId: number = parseInt(value);
    if (centres.find((c) => c.id === selectedId) === undefined) {
      selectedId = 0;
    }

    setEmployer(
      produce(employer, (newEmployer) => {
        setPlacementsLoaded(false);
        getPlacements();
        set(newEmployer, 'onefileOrganisationId', selectedId);
        set(newEmployer, 'onefilePlacementId', 0);

        return newEmployer;
      }),
    );
  };

  const handlePlacementChange = (id: number | string) => {
    let selectedPlacement = placements.find((p) => p.id === id);
    if (selectedPlacement === undefined) {
      id = 0;
    }

    setEmployer(
      produce(employer, (newEmployer) => {
        set(newEmployer, 'onefilePlacementId', id);
        return newEmployer;
      }),
    );
  };

  const handleSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault();
    if (!submited) {
      setSubmited(true);
      let payload = employer;
      let selectedPlacement = placements.find(
        (p) => p.id === employer.onefilePlacementId,
      );
      if (selectedPlacement !== undefined) {
        payload = { ...payload, name: selectedPlacement.name };
      }

      const res = await fetchAPI<string>({
        path: 'EmployerRecords',
        body: payload,
        method: 'POST',
      });
      if (res.success) {
        onCreated && onCreated(res.body || '');
        closeModal();
      } else {
        setSubmited(false);
      }
    }
  };

  const handleEmployerChange = (
    value: string | boolean | number,
    fieldName: string,
  ) => {
    setEmployer(
      produce(employer, (newEmployer) => {
        set(newEmployer, fieldName, value);
        return newEmployer;
      }),
    );
  };

  const handleSectorChange = (i: number, value: string) => {
    setEmployer(
      produce(employer, (draft) => {
        draft.sectors[i].name = value;
        return draft;
      }),
    );
  };

  const addNewSector = () => {
    setEmployer(
      produce(employer, (newEmployer) => {
        newEmployer.sectors.push(createEmptyEmployerSector());
        return newEmployer;
      }),
    );
  };

  const deleteSector = (index: number, e: SyntheticEvent) => {
    e.preventDefault();
    setEmployer(
      produce(employer, (newEmployer) => {
        newEmployer.sectors.splice(index, 1);
        return newEmployer;
      }),
    );
  };

  // field errors
  const nameErr = validateField(employer.name, {
    required: employerNameRadio === 'name',
  });
  const centreErr = hasCentreOptions && employer.onefileOrganisationId === 0;
  const placementErr =
    hasPlacementOptions &&
    employerNameRadio === 'placement' &&
    employer.onefilePlacementId === 0;
  const step1Valid = !nameErr && !centreErr && !placementErr;

  const { contact } = employer;
  const contactNameErr = validateField(contact?.name, { required: true });
  const jobTitleErr = validateField(contact?.jobTitle, { required: true });
  const emailErr = validateField(contact?.email, {
    required: true,
    email: true,
  });
  const step2valid = !contactNameErr && !jobTitleErr && !emailErr;

  const maxSectors = 5;
  const sectorsCount = employer.sectors.length;
  const totalSectorRemaining = maxSectors - sectorsCount;
  const disableSectors = totalSectorRemaining === 0;
  const step3Valid =
    sectorsCount > 0 &&
    totalSectorRemaining >= 0 &&
    employer.sectors.every(
      (sector) => !validateField(sector.name, { required: true }),
    );

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toggleMenu = (isMenuOpen: boolean): void => {
    setIsMenuOpen(isMenuOpen);
  };

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event);
      }}
      style={{ maxWidth: 412 }}
    >
      {step === 0 && (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <h2 data-qa="employerModal-header-create">
              Create employer record
            </h2>
          </Stack>
          {hasCentreOptions && (
            <Stack stackMultiplier={0.5}>
              <h3>Eportfolio centre</h3>
              <p>
                The Eportfolio centre is required for any relevant event
                subscriptions to process as expected.
              </p>
              <Select
                id="select-centre-input"
                placeholder="Select centre"
                options={centreOptions}
                initalValue={currentCentreOption}
                onOptionChanged={(centerOption) =>
                  handleCentreChange(centerOption.value)
                }
              />
            </Stack>
          )}
          <Stack stackMultiplier={1}>
            <h3>Employer name *</h3>
            <p>
              These Eportfolio details are required for any relevant event
              subscriptions to process as expected.
            </p>
            <RadioWrapper className="column">
              <Stack>
                {hasPlacementOptions && (
                  <Radio
                    id="employer-name"
                    label="Enter new Employer name"
                    dataQa="employer-name-radio"
                    inline
                    checked={employerNameRadio === 'name'}
                    onChange={() => {
                      setEmployerNameRadio('name');
                    }}
                  />
                )}
                <TextInput
                  value={employer.name}
                  id="name"
                  error={nameErr}
                  disabled={employerNameRadio !== 'name'}
                  placeholder="Employer name"
                  dataQa="employerModal-input-name"
                  onChangeText={(str) => handleEmployerChange(str, 'name')}
                />
              </Stack>
              {hasPlacementOptions && (
                <Stack>
                  <Radio
                    id="employer-placement"
                    label=" Select Employer placement"
                    dataQa="employer-placement-radio"
                    inline
                    disabled={placementsLoading}
                    checked={employerNameRadio === 'placement'}
                    onChange={() => {
                      setEmployerNameRadio('placement');
                    }}
                  />
                  <FilterSingleItem
                    filterName="employer-placement-filter"
                    items={placementOptions}
                    title="Filter by placement"
                    searchTitle="Select placement"
                    onItemChanged={(placementOption) =>
                      handlePlacementChange(placementOption.id)
                    }
                    toggleMenu={toggleMenu}
                    disabled={
                      placementsLoading || employerNameRadio !== 'placement'
                    }
                  />
                </Stack>
              )}
            </RadioWrapper>
          </Stack>
          <ButtonRow>
            <Button
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Create employer modal - cancel',
                });
                closeModal();
              }}
              variant="subtle"
              dataQa="employerModal-btn-cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Create employer modal - Next',
                });
                e.preventDefault();
                setStep(1);
              }}
              disabled={!step1Valid}
              iconRight={<IconArrowRight responsive />}
              dataQa="employerModal-btn-next"
            >
              Next
            </Button>
          </ButtonRow>
        </Stack>
      )}
      {step === 1 && (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <h2>Add employer contact</h2>
            <p>
              Please enter key contact information for somebody at the
              organisation.
            </p>
          </Stack>
          <Stack stackMultiplier={0.5}>
            <TextInput
              label="Contact name"
              value={employer.contact.name}
              id="contactName"
              error={contactNameErr}
              dataQa="employerModal-input-contact"
              onChangeText={(str) => handleEmployerChange(str, 'contact.name')}
            />
            <TextInput
              label="Job role/position"
              value={employer.contact.jobTitle}
              id="jobTitle"
              error={jobTitleErr}
              dataQa="employerModal-input-job"
              onChangeText={(str) =>
                handleEmployerChange(str, 'contact.jobTitle')
              }
            />
            <TextInput
              label="Email address"
              value={employer.contact.email}
              id="email"
              maxLength={MaxLength.EmailAddress}
              error={emailErr}
              dataQa="employerModal-input-email"
              onChangeText={(str) => handleEmployerChange(str, 'contact.email')}
            />
          </Stack>
          <ButtonRow>
            <Button
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Create employer modal - cancel',
                });
                closeModal();
              }}
              variant="subtle"
              dataQa="employerModal-btn-cancel"
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              dataQa="employerModal-btn-back"
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Create employer modal - Back a step',
                });
                setStep(0);
              }}
            >
              Back
            </Button>
            <Button
              onClick={(e) => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Create employer modal - Next step',
                });
                e.preventDefault();
                setStep(2);
              }}
              disabled={!step2valid}
              dataQa="employerModal-btn-next"
              iconRight={<IconArrowRight responsive />}
            >
              Next
            </Button>
          </ButtonRow>
        </Stack>
      )}
      {step === 2 && (
        <>
          <Stack stackMultiplier={2}>
            <Stack stackMultiplier={0.5}>
              <h2>Add employer sectors</h2>
              <p>
                What sector(s) does the employer offer apprenticeships in? These
                can be assigned to specific courses later.
              </p>
            </Stack>
            <Stack stackMultiplier={0.5}>
              <h3>Sector name</h3>
              {employer.sectors.map((sector, index) => (
                <SectorField key={`Sector_${index}`}>
                  <TextInput
                    value={sector.name}
                    id={`Sector_${index}`}
                    dataQa={`employerModal-input-sector-${index}`}
                    error={validateField(sector.name, { required: true })}
                    onChangeText={(str) => handleSectorChange(index, str)}
                  />
                  {employer.sectors.length > 1 && (
                    <button
                      type="submit"
                      data-qa={`employerModal-btn-delete-sector-${index}`}
                      onClick={(e) => deleteSector(index, e)}
                    >
                      <IconBin />
                      <span className="sr-only">Delete {sector.name}</span>
                    </button>
                  )}
                </SectorField>
              ))}
              <AddSectorButton
                type="button"
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Create employer modal - Add sector',
                  });
                  addNewSector();
                }}
                disabled={disableSectors}
                data-qa="employerModal-btn-add-sector"
              >
                <AddCircle error={disableSectors}>
                  {disableSectors ? <IconError /> : <IconPlus />}
                </AddCircle>
                {disableSectors
                  ? `You can only add five (${sectorsCount}) sectors`
                  : `Add new sector`}
              </AddSectorButton>
            </Stack>
            <ButtonRow>
              <Button
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Create employer modal - cancel',
                  });
                  closeModal();
                }}
                variant="subtle"
                dataQa="employerModal-btn-cancel"
              >
                Cancel
              </Button>
              <Button
                variant="secondary"
                dataQa="employerModal-btn-back"
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Create employer modal - Back a step',
                  });
                  setStep(1);
                }}
              >
                Back
              </Button>
              <Button
                onClick={(e) => {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Create employer modal - Next step',
                  });
                  e.preventDefault();
                  setStep(3);
                }}
                disabled={!step3Valid}
                dataQa="employerModal-btn-next"
                iconRight={<IconArrowRight responsive />}
              >
                Next
              </Button>
            </ButtonRow>
          </Stack>
        </>
      )}
      {step === 3 && (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <h2>Confirm details</h2>
            <p>
              Please check the employer details are correct. The email address
              below will be used to create a OneFile account for the employer.
            </p>
          </Stack>
          <Stack stackMultiplier={0.5}>
            <DetailsCard>
              <Stack stackMultiplier={0.5}>
                <h3>Employer contact details</h3>
                <p data-qa="employerModal-lbl-contact">
                  {employer.contact.name}
                </p>
                <p data-qa="employerModal-lbl-job">
                  {employer.contact.jobTitle}
                </p>
                <p data-qa="employerModal-lbl-email">
                  {employer.contact.email}
                </p>
              </Stack>
            </DetailsCard>
            <DetailsCard>
              <Stack stackMultiplier={0.5}>
                <h3>Employer sectors</h3>
                {employer.sectors.map((sector, index) => (
                  <p
                    key={`employer-sector=${index}`}
                    data-qa={`employerModal-lbl-sector-${index}`}
                  >
                    {sector.name}
                  </p>
                ))}
              </Stack>
            </DetailsCard>
          </Stack>
          <ButtonRow>
            <Button
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Create employer modal - cancel',
                });
                closeModal();
              }}
              variant="subtle"
              dataQa="employerModal-btn-cancel"
            >
              Cancel
            </Button>
            <Button
              variant="subtle"
              dataQa="employerModal-btn-back"
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Create employer modal - Back a step',
                });
                setStep(2);
              }}
            >
              Back
            </Button>
            <Button
              onClick={(e) => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Create employer modal - Create record',
                });
                handleSubmit(e);
              }}
              disabled={!step3Valid}
              dataQa="employerModal-btn-create"
            >
              Create record
            </Button>
          </ButtonRow>
        </Stack>
      )}
    </form>
  );
};

export default CreateEmployer;
